import React from "react"
import Layout from "../components/layout"
import Img from "gatsby-image"
import { graphql, Link } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"
import tw, { styled } from "twin.macro"
import Footer from "../components/Footer"
import LogRocket from 'logrocket';
LogRocket.init('ziiqiw/studio-conscious');

const Full = styled.div`
  ${tw`w-3/4 mr-auto ml-auto`}
  @media(min-width:1024px) {
    padding: 50px 200px;
  }
  line-height: 2;
  h1 {
    text-align: center;
    @media (max-width: 600px) {
      text-align: left;
    }
  }
`
const LeftRight = styled.div`
  ${tw`w-3/4 mr-auto ml-auto grid lg:grid-cols-2`}
  img {
    @media (min-width: 600px) {
      padding-right: 75px;
    }
  }
  h1 {
    text-align: center;
    @media (max-width: 600px) {
      text-align: left;
    }
  }
`
const RightLeft = styled.div`
  ${tw`w-3/4 mr-auto ml-auto grid lg:grid-cols-2`}
  h1 {
    text-align: center;
    @media (max-width: 600px) {
      text-align: left;
    }
  }
`

const Header = styled.h1`
  ${tw`text-center`}
  margin-top:100px;
  @media (min-width: 1024px) {
    margin-top: 200px;
  }
  font-weight: 500;
  font-family: "Playfair Display";
`

const Wrap = styled.div`
  line-height: 2;
  a {
    color: #8f9687;
    text-decoration: underline;
    //font-weight: 700;
  }
`

const Author = styled.h2`
  text-align: center;
`

const serializers = {
  types: {
    code: props => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    ),
  },
}

const StoreDetails = ({ data, location }) => {
  return (
    <>
      {data.allSanityBlog.edges.map(({ node: blog }, i) => {
        return (
          <>
            <Header>{blog.header}</Header>
          </>
        )
      })}
    </>
  )
}

const WithLayout = Component => {
  return props => (
    <>
      <Layout location={props.location}>
        <Component {...props} />
      </Layout>
      {props.data.allSanityBlog.edges.map(({ node: blog }, i) => {
        return (
          <React.Fragment key={i}>
            {blog.blogPostBlock.map(block => {
              return (
                <Wrap>
                  <div>
                    {block._type === "full" && (
                      <Full>
                        <h1>{block.header}</h1>
                        <Img fluid={block.image && block.image.asset.fluid} />
                        <BlockContent
                          blocks={block._rawText}
                          serializers={serializers}
                        />
                      </Full>
                    )}
                    {block._type === "left-right" && (
                      <LeftRight>
                        <Img fluid={block.image && block.image.asset.fluid} />
                        <div>
                          <h1>{block.header}</h1>
                          <BlockContent
                            blocks={block._rawText}
                            serializers={serializers}
                          />
                        </div>
                      </LeftRight>
                    )}
                    {block._type === "right-left" && (
                      <RightLeft>
                        <div>
                          <h1>{block.header}</h1>
                          <BlockContent
                            blocks={block._rawText}
                            serializers={serializers}
                          />
                        </div>
                        <Img fluid={block.image && block.image.asset.fluid} />
                      </RightLeft>
                    )}
                  </div>
                </Wrap>
              )
            })}
            <Author>Author:{blog.author}</Author>
            <Footer />
          </React.Fragment>
        )
      })}
    </>
  )
}

export default WithLayout(StoreDetails)

export const query = graphql`
  query BlogDetailsQuery($slug: String!) {
    allSanityBlog(filter: { _id: { eq: $slug } }) {
      edges {
        node {
          header
          author
          _rawBlogPostBlock(resolveReferences: { maxDepth: 10 })
          blogPostBlock {
            ... on SanityFull {
              _key
              _type
              header
              _rawText
              image {
                asset {
                  fluid(maxWidth: 700) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
            ... on SanityLeftRight {
              _key
              _type
              _rawText
              image {
                asset {
                  fluid(maxWidth: 700) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              header
            }
            ... on SanityRightLeft {
              _key
              _type
              _rawText
              image {
                asset {
                  fluid(maxWidth: 700) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              header
            }
          }
        }
      }
    }
  }
`
